<template>
  <div>
    <topic-component :topic="$t('SideBarItems.reports.list.sessionReport')"></topic-component>
    <b-card>
      <b-row>
        <b-col lg="4" md="6" sm="12">
          <b-form-group label-cols="12"
                        :label="$t('common.dateTime')" label-for="input-sm">
            <date-time-range-component v-model="time" type="date"/>
          </b-form-group>
        </b-col>
        <b-col style="margin-top: 35px">
          <button type="button" class="btn waves-effect waves-light btn-gradient" @click="filterSearch()">
            {{ $t('common.Search') }}
          </button>
          <button type="button" class="btn waves-effect waves-light btn-outline ml-2" @click="resetSearch">
            {{ $t('common.resetSearch') }}
          </button>
        </b-col>
      </b-row>
      <hr>
      <b-row align-v="center" class="pb-3">
        <b-col>
          <div class="d-flex align-items-center">
            <span class="mr-3">{{ $t('common.perPage') }}</span>
            <div>
              <b-form-select
                  v-model="form.$limit"
                  :options="pageOptions"
                  size="sm"
                  @change="getSessionReportList"
              />
            </div>
            <div class="ml-3">
              <total-list-component :total="totalAll"/>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-table
            responsive
            class="mb-0"
            :items="items"
            :fields="fields"
            :busy="isBusy"
            :empty-text="$t('empty')"
            show-empty
        >
          <template #head()="{ label, field: { key, sortable }}">
            {{ $t(label) }}
          </template>
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong> {{ $t('loading') }}</strong>
            </div>
          </template>
          <template #empty="scope">
            <empty-table/>
          </template>
          <template #cell(createdAt)="data">
            {{ changeDateFormat(data.item.createdAt) }}
          </template>
          <template #cell(device_ip)="data">
            {{ data.item.ip ? data.item.ip : '-' }}
          </template>
          <template #cell(device)="data">
            <div class="text-primary">
              {{ changeDeviceName(data.item) || '-'}}
            </div>
            <small class="font-size-12">
              {{ changeModelName(data.item) || '-'}}
            </small>
          </template>
          <template #cell(createdAt)="data">
            {{ changeDateFormat(data.item.createdAt) }}
          </template>
          <template #cell(status)="data">
            <b-badge pill class="px-2 text-white badge"
                     :variant="colorButton(data.item.status)">
              {{ filterLangValue(login_session_status, data.item.status) || '-' }}
            </b-badge>

          </template>
        </b-table>
        <div class="ml-auto">
          <b-pagination
              v-model="form.$skip"
              :total-rows="total_rows"
              :per-page="form.$limit"
              align="fill"
              class="my-0"
              size="sm"
              @input="getSessionReportList"
          ></b-pagination>
        </div>
      </b-row>
    </b-card>
  </div>
</template>

<script>

import moment from "moment";
import SelectFilterComponent from "@/components/common/selectFilterComponent.vue";
import TopicComponent from "@/components/common/topicComponent.vue";
import DateTimeRangeComponent from "@/components/common/dateTimeRangeComponent.vue";
import reportApi from "@/repository/reportApi";
import functionsCommon from "@/common/functions";
import masterData from '@/common/masterData.json'
import EmptyTable from "@/components/common/emptyTable.vue";
import TotalListComponent from "@/components/common/totalList.vue";

export default {
  name: "sessionReport",
  components: {TotalListComponent, EmptyTable, SelectFilterComponent, TopicComponent, DateTimeRangeComponent},
  data: () => ({
    totalAll: 0,
    form: {
      date_from: null,
      date_to: null,
      $skip: 1,
      $limit: 10,
    },
    isBusy: false,
    time: [
      new Date(moment(new Date()).format('YYYY-MM-DD')),
      new Date(moment(new Date()).format('YYYY-MM-DD'))
    ],
    fields: [
      {
        key: "createdAt",
        label: "report.sessionReport.date",
        class: 'text-left'
      },
      {
        key: "device_ip",
        label: "report.sessionReport.ip",
        class: 'text-left'
      },
      {
        key: "device",
        label: "report.sessionReport.device",
        class: 'text-left'
      },
      {
        key: "status",
        label: "report.sessionReport.status",
      }
    ],
    items: [],
    total_rows: 1,
    pageOptions: [10, 15, 50, 100],
    login_session_status: masterData.login_session_status
  }),
  mounted() {
    this.getSessionReportList()
  },
  methods: {
    getSessionReportList() {
      this.isBusy = true
      this.form.date_from = moment(this.time[0]).format('YYYY-MM-DD')
      this.form.date_to = moment(this.time[1]).format('YYYY-MM-DD')
      reportApi.getSessionReportList(_.cloneDeep(this.form)).then(resp => {
        if (resp) {
          this.items = resp?.data
          this.total_rows = resp?.total
          this.totalAll = resp?.total
        }
      }).finally(() => {
        this.isBusy = false
      })
    },
    resetSearch() {
      this.form = {
        date_from: null,
        date_to: null,
        $skip: 1,
        $limit: 10,
      }
      this.time = [
        new Date(moment(new Date()).format('YYYY-MM-DD')),
        new Date(moment(new Date()).format('YYYY-MM-DD'))
      ]
      this.getSessionReportList()
    },
    changeDateFormat(date) {
      return functionsCommon.changeDateFormat(date)
    },
    filterLangValue(arr, val) {
      return functionsCommon.filterMasterData(arr, val, this.$i18n.locale)
    },
    colorButton(status) {
      switch (status) {
        case 'success':
          return 'success';
        case 'failed' :
          return 'danger'
        default :
          return null

      }
    },
    changeDeviceName(data) {
      return _.get(data, 'device', '')
    },
    changeModelName(data) {
      let brand = _.get(data, 'brand', '')
      let model = data?.model ? `: ${_.get(data, 'model', '')}` : ''
      return brand + ' ' + model
    },
    filterSearch() {
      this.form.$skip = 1
      this.getSessionReportList()
    }
  },
};
</script>
<style scoped>
.action-button {
  cursor: pointer;
}
</style>
